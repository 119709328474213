<template>
  <div
    class="sb-catalog__item sb-catalog__item_4"
    :class="'sb-catalog__item_delay-' + time"
    v-if="product.product_is_active > 0 ? true : false"
  >
    <div class="sb-catalog__item-content">
      <div class="sb-catalog__item-img-wrapper">
        <img :src="product.product_img" :alt="product.product_name" />
      </div>
    </div>
    <div class="sb-catalog__item-footer">
      <div class="sb-catalog__item-name">
        {{ product.product_name }}
      </div>
      <div class="sb-catalog__item-price">
        <div class="sb-catalog__price">
          <span class="sb-catalog__price-number">{{ product_price }}</span
          ><span class="sb-catalog__price-currency">{{
            GET_CONFIG_LIST_DATA.config_currency
          }}</span>
        </div>
        <div class="sb-catalog__price-old" v-if="product_price_old">
          <span class="sb-catalog__price-old-number">{{
            product_price_old
          }}</span>
          <span class="sb-catalog__price-old-currency">{{
            GET_CONFIG_LIST_DATA.config_currency
          }}</span>
        </div>
      </div>
      <quantity-fake-input
        v-if="!is_in_basket"
        :product="product"
        :key="product.product_basket_id"
      />
      <button
        class="sb-catalog__item-button"
        @click="addToBasket()"
        v-if="!is_in_basket && product.product_is_quantity == 0"
      >
        <img
          height="15"
          src="@/assets/img/basket.svg"
          alt="В корзину"
          title="Добавить в корзину"
        />
        <span>В корзине</span>
      </button>

      <button
        class="sb-catalog__item-button"
        @click="addToBasket()"
        v-if="is_in_basket"
      >
        <img
          height="15"
          src="@/assets/img/basket.svg"
          alt="В корзину"
          title="Добавить в корзину"
        />
        <span>В корзину</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import QuantityFakeInput from "./inputs/QuantityFakeInput.vue";
export default {
  props: {
    product: Object,
    time: Number,
  },
  components: { QuantityFakeInput },
  data() {
    return {
      // quantity: this.GET_BASKET_DATA[this.product.product_id].product_quantity,
      product_price: Math.floor(this.product.product_price * 100) / 100,
      product_price_old: Math.floor(this.product.product_price_old * 100) / 100,
    };
  },
  computed: {
    ...mapGetters(["GET_BASKET_DATA", "GET_CONFIG_LIST_DATA"]),
    is_in_basket() {
      let basket_data = this.GET_BASKET_DATA; // []
      let found = basket_data.some(
        (item) => item.product_id == this.product.product_id
      );

      if (found) {
        return false;
      }

      return true;
    },
  },
  methods: {
    addToBasket() {
      let basket_data = this.GET_BASKET_DATA; // []
      var found = basket_data.some(
        (item) => item.product_id == this.product.product_id
      );
      if (!found) {
        let basket_item = this.product;
        basket_item.product_quantity = 1;
        basket_item.product_basket_id =
          this.product.product_id + "_" + Math.random(1000);

        basket_data.push(basket_item);

        this.$store.commit("SET_BASKET_DATA", basket_data);
        localStorage.setItem("sb_ls", JSON.stringify(basket_data));

        // вызов модального окна
        this.$store.commit("SET_ALERT_CONTENT", {
          title: "",
          description: "Товар добавлен в корзину",
          status: "success",
          button_text_1: "Продолжить",
          button_text_2: "В корзину",
          is_open: true,
        });
        document.body.classList.add("hidden");
      } else {
        let new_basket_data = basket_data.filter(
          (item) => item.product_id !== this.product.product_id
        );
        let basket_item = this.product;
        basket_item.product_quantity = 1;
        basket_item.product_basket_id =
          this.product.product_id + "_" + Math.random(1000);
        basket_item.offer = {
          basket_offer_id: "",
        };

        new_basket_data.push(basket_item);
        this.$store.commit("SET_BASKET_DATA", new_basket_data);
        localStorage.setItem("sb_ls", JSON.stringify(new_basket_data));
        console.log("Такой товар уже есть в корзине");

        return false;
      }
    },
  },
};
</script>

<style lang="scss">
.sb-catalog {
  &__item {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    padding: 15px;
    border-radius: 10px;
    font-size: 14px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    // align-content: flex-start;
    &_1 {
      width: 100%;
      margin: 0 1% 1% 2%;
    }
    &_4 {
      width: 23%;
      margin: 0 1% 2%;
      @media (max-width: 1199px) {
        width: 31.33%;
      }
      @media (max-width: 991px) {
        padding: 10px;
      }
      @media (max-width: 768px) {
        width: 48%;
      }
      // @media (max-width: 359px) {
      //   width: 100%;
      // }
    }
    &_3 {
      width: 31.33%;
      margin: 0 1% 2%;
    }
    &_delay-0 {
    }
    &_delay-1 {
    }
    &__delay-2 {
    }
    &_delay-3 {
    }
    &_delay-4 {
    }
    &_delay-5 {
    }
    &_delay-6 {
    }
    &_delay-7 {
    }
    &_delay-8 {
    }
    &_delay-9 {
    }
    &_delay-10 {
    }
    &_delay-11 {
    }
    &_delay-12 {
    }
  }

  &__item-content {
    width: 100%;
  }

  &__item-name {
    display: -webkit-box;
    -webkit-line-clamp: 2; // количество строк
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 15px;
    line-height: 1.3;
    font-weight: 500;
    height: 35px;
    width: 100%;
    @media (max-width: 768px) {
      margin-bottom: 0;
    }
    @media (max-width: 480px) {
      font-size: 14px;
      color: #686868;
    }
    @media (max-width: 379px) {
      font-size: 13px;
    }
    @media (max-width: 359px) {
      font-size: 12px;
    }
  }

  &__item-img-wrapper {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
    margin: 0 0 30px;
    line-height: 0;
    @media (max-width: 768px) {
      margin: 0 0 15px;
    }

    img {
      width: 100%;
      height: auto;
      line-height: 0;
    }
  }
  &__item-footer {
    width: 100%;
    margin: 15px 0 0;
    align-self: flex-end;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &_2 {
      justify-content: space-between;
    }
  }

  &__item-price {
    width: 48%;
    height: 40px;
    // padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    border-radius: 5px;
    color: #3d3d3d;
    @media (max-width: 768px) {
      width: 100%;
      display: flex;
      align-content: flex-start;
      margin-bottom: 10px;
    }
    @media (max-width: 480px) {
      height: 35px;
    }
    // border: 1px solid #f0f0f0;
    // background: rgb(255, 255, 255);
    // background: linear-gradient(
    //   90deg,
    //   rgba(255, 255, 255, 0) 10%,
    //   rgba(249, 249, 249, 1) 95%
    // );
  }
  &__price {
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    width: 100%;
    @media (max-width: 991px) {
      font-size: 13px;
    }
    @media (max-width: 768px) {
      text-align: center;
      font-size: 14px;
    }
  }
  &__price-old {
    width: 100%;
    font-size: 12px;
    color: #8f8f8f;
    text-decoration: line-through;
    font-weight: 400;
    text-align: left;
    margin-top: 3px;
    @media (max-width: 991px) {
      font-size: 11px;
    }
    @media (max-width: 768px) {
      text-align: center;
      font-size: 12px;
    }
  }

  &__item-button {
    width: 48%;
    border-radius: 30px;
    height: 40px;
    color: #fff;
    border: none;
    background: #0391fd;
    font-size: 14px;
    transition: 0.3s;
    cursor: pointer;
    padding-top: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      width: 100%;
      margin-top: 10px;
    }
    @media (max-width: 480px) {
      height: 35px;
    }
    img {
      margin-right: 5px;
      @media (max-width: 768px) {
        margin-right: 10px;
      }
    }
    &:hover {
      transition: 0.3s;
      background: #1bbc9b;
    }
  }
}
</style>
