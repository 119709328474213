<template>
  <ul class="sb-catalog__pagination" v-if="total_pages > 1">
    <li class="sb-catalog__pagination-item sb-catalog__pagination-item_first">
      <button
        class="sb-catalog__pagination-button sb-catalog__pagination-button_first"
        :class="
          current_page_number == first_page
            ? 'sb-catalog__pagination-button_active'
            : ''
        "
        @click="change_page(1)"
      >
        {{ first_page }}
      </button>
    </li>
    <li
      v-if="total_pages > 5"
      class="sb-catalog__pagination-item sb-catalog__pagination-item_dots"
    >
      ...
    </li>
    <li v-if="total_pages > 2" class="sb-catalog__pagination-item">
      <button
        class="sb-catalog__pagination-button"
        :class="
          current_page_number == first_button_number
            ? 'sb-catalog__pagination-button_active'
            : ''
        "
        @click="change_page(first_button_number)"
      >
        {{ first_button_number }}
      </button>
    </li>
    <li v-if="total_pages > 3" class="sb-catalog__pagination-item">
      <button
        class="sb-catalog__pagination-button"
        :class="
          current_page_number == second_button_number
            ? 'sb-catalog__pagination-button_active'
            : ''
        "
        @click="change_page(second_button_number)"
      >
        {{ second_button_number }}
      </button>
    </li>
    <li v-if="total_pages > 4" class="sb-catalog__pagination-item">
      <button
        class="sb-catalog__pagination-button"
        :class="
          current_page_number == third_button_number
            ? 'sb-catalog__pagination-button_active'
            : ''
        "
        @click="change_page(third_button_number)"
      >
        {{ third_button_number }}
      </button>
    </li>
    <li
      v-if="total_pages > 5"
      class="sb-catalog__pagination-item sb-catalog__pagination-item_dots"
    >
      ...
    </li>
    <li class="sb-catalog__pagination-item sb-catalog__pagination-item_last">
      <button
        class="sb-catalog__pagination-button sb-catalog__pagination-button_last"
        :class="
          current_page_number == total_pages
            ? 'sb-catalog__pagination-button_active'
            : ''
        "
        @click="change_page(total_pages)"
      >
        {{ total_pages }}
      </button>
    </li>
  </ul>
</template>

<script>
// import axios from "axios";
import { mapGetters } from "vuex";
// import router from "vue-router";
export default {
  props: {
    count: Number,
  },
  data() {
    return {
      // current_page_number: 1,
      first_page: 1,
      first_button_number: 2,
      second_button_number: 3,
      third_button_number: 4,
    };
  },
  computed: {
    ...mapGetters([
      "GET_PRODUCT_LIST_DATA",
      "GET_PRODUCT_COUNT_DATA",
      "GET_CONFIG_LIST_DATA",
      "GET_BASKET_DATA",
    ]),

    current_page_number() {
      if (this.$route.params.slug > 1) {
        return this.$route.params.slug;
      }
      return 1;
    },

    total_pages() {
      let count = this.GET_PRODUCT_COUNT_DATA / 12;
      if (this.GET_PRODUCT_COUNT_DATA % 12) {
        return Math.ceil(count);
      }
      return count;
    },
  },
  methods: {
    change_page(page_number) {
      if (page_number == this.current_page_number) {
        return false;
      }

      if (this.total_pages <= 5 && this.total_pages >= 1) {
        if (page_number == 1) {
          this.$router.push({ path: "/" });
          return;
        }
        this.$router.push({ path: `/page${page_number}` });
      } else {
        console.log("big");
        console.log(page_number);
        if (page_number == 1 || page_number == this.total_pages) {
          this.$router.push({ path: "/" });
          return;
        }

        if (page_number > 2 && page_number < this.total_pages - 1) {
          this.first_button_number = page_number - 1;
          this.second_button_number = page_number;
          this.third_button_number = page_number + 1;
        }

        if (page_number == 2) {
          this.first_button_number = page_number;
          this.second_button_number = page_number + 1;
          this.third_button_number = page_number + 2;
        }

        if (page_number == this.total_pages - 1) {
          this.first_button_number = page_number - 2;
          this.second_button_number = page_number - 1;
          this.third_button_number = page_number;
        }
        this.$router.push({ path: `/page${page_number}` });
      }
    },
  },
};
</script>

<style lang="scss">
.sb-catalog {
  &__pagination {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  &__pagination-item {
    padding: 0;
    margin: 0;
    &_dots {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      border: none;
      padding: 0;
      color: #8e8e8e;
      line-height: 1;
      width: 30px;
    }
  }
  &__pagination-button {
    text-align: center;
    font-size: 12px;
    width: 30px;
    height: 30px;
    padding: 0;
    background: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background: #f9f9f9;
    color: #27292b;
    margin: 0 2px;
    &:hover {
      background: #1bbc9b;
      color: #fff;
    }
    &_first,
    &_last {
      background: #f9f9f9;
    }
    &_first {
      margin: 0 2px 0 0;
    }
    &_last {
      margin: 0 0 0 2px;
    }
    &_active {
      background: #0391fd;
      color: #fff;
      &:hover {
        background: #0391fd;
        color: #fff;
      }
    }
  }
}
</style>
