import { createStore } from "vuex";
import Axios from "axios";

export default createStore({
  state: {
    product_list: [],
    product_count: "",
    // basket_data: {},
    basket_data: [],
    config_list: "",
    is_open_basket: false,
    alert_content: {
      title: "",
      description: "",
      status: "",
      button_text_1: "",
      button_text_2: "",
      is_open: false,
    },
  },
  getters: {
    GET_PRODUCT_LIST_DATA: (state) => {
      return state.product_list;
    },
    GET_PRODUCT_COUNT_DATA: (state) => {
      return state.product_count;
    },
    GET_CONFIG_LIST_DATA: (state) => {
      return state.config_list[0];
    },
    GET_BASKET_DATA: (state) => {
      return state.basket_data;
    },
    GET_BASKET_CONDITION: (state) => {
      return state.is_open_basket;
    },
    GET_ALERT_CONTENT: (state) => {
      return state.alert_content;
    },
  },
  mutations: {
    SET_PRODUCT_LIST_DATA: (state, payload) => {
      // console.log("***");
      // console.log(payload);
      // console.log("***");
      state.product_list = payload;
    },
    SET_PRODUCT_COUNT_DATA: (state, payload) => {
      state.product_count = payload;
    },
    SET_CONFIG_LIST_DATA: (state, payload) => {
      state.config_list = payload;
    },
    SET_BASKET_DATA: (state, payload) => {
      state.basket_data = payload;
    },
    CHANGE_BASKET_CONDITION: (state) => {
      return (state.is_open_basket = !state.is_open_basket);
    },
    SET_ALERT_CONTENT: (state, payload) => {
      state.alert_content.title = payload.title;
      state.alert_content.description = payload.description;
      state.alert_content.status = payload.status;
      state.alert_content.button_text_1 = payload.button_text_1;
      state.alert_content.button_text_2 = payload.button_text_2;
      state.alert_content.is_open = payload.is_open;
    },
  },
  actions: {
    PRODUCT_LIST_ACTION: async (context) => {
      let { data } = await Axios.post(
        "/admin/product/front/get_product_list_frontend.php",
        {
          limit: 12,
          from: 0,
          category: false,
        },
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      context.commit("SET_PRODUCT_LIST_DATA", data);
    },

    PRODUCT_COUNT_ACTION: async (context) => {
      let { data } = await Axios.post(
        "/admin/product/front/get_product_count_frontend.php"
      );
      context.commit("SET_PRODUCT_COUNT_DATA", data);
    },

    CONFIG_LIST_ACTION: async (context) => {
      let { data } = await Axios.post(
        "/admin/config/front/get_config_frontend.php"
      );
      context.commit("SET_CONFIG_LIST_DATA", data);
    },
  },
  modules: {},
});
