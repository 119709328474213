<template>
  <div
    class="sb-basket"
    :class="{ 'sb-basket__active': GET_BASKET_CONDITION }"
    @click.self="close_basket"
  >
    <div
      class="sb-basket__inner"
      :class="{ 'sb-basket__inner_active': GET_BASKET_CONDITION }"
    >
      <div class="sb-basket__main-content">
        <div class="sb-basket__header">
          <div class="sb-basket__title">
            <span v-show="!is_form_visible"
              >Всего товаров: {{ total_quantity }}</span
            >
            <button
              v-show="!is_form_visible"
              class="sb-basket__clear-button"
              @click="clear_button()"
            >
              Очистить корзину
            </button>
            <button
              v-show="is_form_visible"
              @click="is_form_visible = !is_form_visible"
              class="sb-basket__clear-button"
            >
              Вернуться в корзину
            </button>
          </div>

          <button class="sb-basket__close-button" @click="close_basket">
            <svg
              width="30"
              height="30"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m7 7 18 18M7 25 25 7"
                fill="none"
                stroke="#f9f9f9"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2px"
                class="stroke-000000"
              ></path>
            </svg>
          </button>
        </div>
        <div class="sb-basket__empty-section" v-if="!GET_BASKET_DATA.length">
          <svg
            class="sb-basket__empty-img"
            viewBox="0 0 576 512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m560 192-101.6.002-93.11-179.1c-6.062-11.72-20.61-16.37-32.36-10.22-11.78 6.094-16.34 20.59-10.22 32.34l81.61 156.9H171.7l81.61-156.9c6.09-11.702 1.59-26.2-10.21-32.294-11.8-6.125-26.3-1.5-32.4 10.222l-93.11 179.1L16 192c-8.836 0-16 7.164-16 15.1v32c0 8.836 7.164 15.1 16 15.1h23.11l45.75 205.9c6.51 31.1 32.44 51.9 62.44 51.9h281.3c29.1 0 55.97-20.83 62.48-50.12l45.75-205.9H560c8.838 0 16-7.164 16-15.1v-32c0-9.68-7.2-16.88-16-16.88zM192 432c0 8.834-7.166 15.1-16 15.1-8.832 0-16-7.166-16-15.1V304c0-8.834 7.168-15.1 16-15.1 8.834 0 16 7.166 16 15.1v128zm112 0c0 8.834-7.166 15.1-16 15.1-8.832 0-16-7.165-16-15.1V304c0-8.834 7.168-15.1 16-15.1 8.834 0 16 7.166 16 15.1v128zm112 0c0 8.834-7.166 15.1-16 15.1-8.832 0-16-7.166-16-15.1V304c0-8.834 7.168-15.1 16-15.1 8.834 0 16 7.166 16 15.1v128z"
              fill="#0391fd"
              class="fill-000000"
            ></path>
          </svg>
          <div class="sb-basket__empty-text">Корзина пуста</div>
        </div>

        <form
          class="sb-basket__form"
          id="sb-basket__form"
          ref="sb_basket_form"
          autocomplete="off"
          @keydown.enter.prevent="submit()"
        >
          <input type="hidden" name="sv" value="" />
          <div v-show="!is_form_visible">
            <template
              v-for="product in GET_BASKET_DATA"
              :key="product.product_basket_id"
            >
              <min-basket-product-inputs :product="product" />
            </template>
          </div>
          <div v-show="is_form_visible">
            <min-basket-contact-inputs :config="GET_CONFIG_LIST_DATA" />
          </div>
          <input
            v-if="GET_CONFIG_LIST_DATA != undefined"
            type="hidden"
            name="total_price"
            :value="total_price"
          />
        </form>
      </div>

      <div class="sb-basket__footer" v-if="total_quantity">
        <div class="sb-basket__informer" v-if="is_footer_informer_visible">
          <div class="sb-basket__informer-text">{{ footer_informer_text }}</div>
          <button class="sb-basket__informer-button" @click="update_basket">
            Обновить
          </button>
        </div>
        <div class="sb-basket__footer-inner" v-if="!is_footer_informer_visible">
          <div class="sb-basket__quantity" v-show="!is_form_visible">
            Общая стоимость:
            <span v-if="GET_CONFIG_LIST_DATA != undefined">
              {{ total_price }}
              {{ GET_CONFIG_LIST_DATA.config_currency }}
            </span>
          </div>
          <button
            type="submit"
            v-show="!is_form_visible"
            class="sb-basket__checkout"
            @click="is_form_visible = !is_form_visible"
          >
            Оформить заказ
          </button>
          <button
            v-show="is_form_visible"
            class="sb-basket__clear-button"
            @click="is_form_visible = !is_form_visible"
          >
            Вернуться в корзину
          </button>
          <button
            v-show="is_form_visible"
            type="submit"
            form="sb-basket__form"
            class="sb-basket__checkout"
            @click.prevent="submit()"
            :disabled="is_checkout_disable"
          >
            <span class="sb-basket__checkout-text" v-if="!is_loader_visible"
              >Подтвердить заказ</span
            >
            <loader-dots v-if="is_loader_visible" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import MinBasketProductInputs from "@/components/MinBasketProductInputs.vue";
// import MinBasketButton from "./MinBasketButton.vue";
import MinBasketContactInputs from "./MinBasketContactInputs.vue";
import LoaderDots from "@/components/LoaderDots.vue";
// import MinBasketContactInputs from "@/components/MinBasketContactInputs.vue";
// import MinBasketTotal from "@/components/MinBasketTotal.vue";
export default {
  components: {
    MinBasketProductInputs,
    MinBasketContactInputs,
    LoaderDots,
  },
  data() {
    return {
      is_form_visible: false,
      is_form_valid: true,
      is_loader_visible: false,
      is_checkout_disable: false,
      is_footer_informer_visible: false,
      footer_informer_text: "",
    };
  },

  computed: {
    ...mapGetters([
      "GET_BASKET_DATA",
      "GET_CONFIG_LIST_DATA",
      "GET_BASKET_CONDITION",
    ]),
    total_quantity() {
      let quantity = 0;
      let product_arr = this.GET_BASKET_DATA;
      for (let i = 0; i < product_arr.length; i++) {
        quantity += Math.floor(product_arr[i].product_quantity);
      }

      return quantity;
    },

    total_price() {
      let summ = 0;
      let product_arr = this.GET_BASKET_DATA;
      for (let i = 0; i < product_arr.length; i++) {
        summ +=
          Math.floor(
            product_arr[i].product_quantity * product_arr[i].product_price * 100
          ) / 100;
      }

      return Math.floor(summ * 100) / 100;
    },
  },

  methods: {
    clear_button() {
      this.$store.commit("SET_BASKET_DATA", []);
      localStorage.setItem("sb_ls", []);
    },
    close_basket() {
      this.$store.commit("CHANGE_BASKET_CONDITION");
      this.is_form_visible = false;
      document.body.classList.remove("hidden");
    },
    validInputs(fd) {
      let config = this.GET_CONFIG_LIST_DATA;
      for (var pair of fd.entries()) {
        if (
          (Number(config.config_is_name_required) &&
            pair[0] == "user_name" &&
            pair[1] == "") ||
          (Number(config.config_is_tel_required) &&
            pair[0] == "user_tel" &&
            pair[1] == "") ||
          (Number(config.config_is_email_required) &&
            pair[0] == "user_mail" &&
            pair[1] == "") ||
          (Number(config.config_is_street_required) &&
            pair[0] == "user_street" &&
            pair[1] == "") ||
          (Number(config.config_is_city_required) &&
            pair[0] == "user_city" &&
            pair[1] == "") ||
          (Number(config.config_is_house_number_required) &&
            pair[0] == "user_house_number" &&
            pair[1] == "") ||
          (Number(config.config_is_apartment_number_required) &&
            pair[0] == "user_apartment_number" &&
            pair[1] == "") ||
          (Number(config.config_is_postcode_required) &&
            pair[0] == "user_postindex" &&
            pair[1] == "") ||
          (Number(config.config_is_comment_required) &&
            pair[0] == "user_comment" &&
            pair[1] == "")
        ) {
          console.log("Заполните все обязательные поля");
          this.is_form_valid = false;

          this.$store.commit("SET_ALERT_CONTENT", {
            title: "",
            description:
              "Заполните все обязательные поля, помеченные звездочкой",
            status: "error",
            button_text_1: "ОК",
            button_text_2: "",
            is_open: true,
          });
          document.body.classList.add("hidden");
          return false;
        } else {
          this.is_form_valid = true;
        }
      }
    },
    submit() {
      let fd = new FormData(this.$refs.sb_basket_form);
      this.validInputs(fd);
      if (this.is_form_valid) {
        this.is_loader_visible = true;
        this.is_checkout_disable = true;
        axios({
          url: "/admin/send_basket_data/front/send_basket.php",
          method: "post",
          headers: { "Content-Type": "multipart/form-data" },
          data: fd,
        }).then((response) => {
          console.log(response);
          this.is_loader_visible = false;
          this.is_checkout_disable = false;
          if (response.data == "success") {
            this.close_basket();
            this.clear_button();
            // вызов модального окна
            this.$store.commit("SET_ALERT_CONTENT", {
              title: "",
              description: "Заказ успешно оформлен. Ожидайте звонка менеджера",
              status: "success",
              button_text_1: "OK",
              button_text_2: "",
              is_open: true,
            });
            document.body.classList.add("hidden");
          }

          // Данные в корзине больше не актуальны. Обновите корзину
          if (response.data == "DATACHANGED") {
            this.is_footer_informer_visible = true;
            this.footer_informer_text =
              "Данные в корзине больше не актуальны. Обновите корзину";
          }

          if (response.data == "NOTCONFIGSENDMAIL") {
            // вызов модального окна
            this.$store.commit("SET_ALERT_CONTENT", {
              title: "Возникла ошибка",
              description:
                "Свяжитесь с нами по телефону, указанному в контактах",
              status: "error",
              button_text_1: "OK",
              button_text_2: "",
              is_open: true,
            });
            document.body.classList.add("hidden");
            this.is_checkout_disable = false;
          }
        });
      }
    },

    // проверка данных, пришедших из localstorage
    update_basket() {
      // Актуализация товаров в корзине
      let ls_data = JSON.parse(localStorage.getItem("sb_ls"));
      let ls_data_id_arr = [];
      if (ls_data !== null) {
        for (let data of ls_data) {
          ls_data_id_arr.push({
            product_id: data.product_id,
            product_quantity: data.product_quantity,
          });
          // console.log("+++++---+++++");
          // console.log(ls_data_id_arr);
          // console.log("+++++---+++++");
        }
      }

      if (ls_data_id_arr.length) {
        axios({
          url: "/admin/product/front/check_basket_product.php",
          method: "post",
          headers: { "Content-Type": "multipart/form-data" },
          data: {
            arr_id: ls_data_id_arr,
          },
        }).then((response) => {
          // console.log("---***---");
          // console.log(response);
          // console.log("---***---");

          this.$store.commit("SET_BASKET_DATA", response.data);
          localStorage.setItem("sb_ls", JSON.stringify(response.data));
        });
      }
      this.is_form_visible = false;
      this.is_footer_informer_visible = false;
      this.footer_informer_text = "";
    },
  },

  beforeMount() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    // We listen to the resize event
    window.addEventListener("resize", () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  },
};
</script>
<style lang="scss">
:root {
  --app-height: 100%;
}
.sb-basket {
  position: fixed;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  display: flex;
  justify-content: flex-end;
  z-index: 100;
  overflow: hidden;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  @media (max-width: 991px) {
    height: calc(var(--vh, 1vh) * 100);
  }
  * {
    box-sizing: border-box;
  }
  &__active {
    opacity: 1;
    visibility: visible;
    transition: 0.3s;
  }
  &__inner {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    max-width: 600px;
    background: #f9f9f9;
    box-shadow: 5px 0 35px rgba(0, 0, 0, 0.2);
    margin: 0 -600px 0 0;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
    &_active {
      margin: 0;
      opacity: 1;
      visibility: visible;
      transition: 0.3s;
    }
  }

  &__main-content {
    width: 100%;
    height: calc(100vh - 67px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    @media (max-width: 991px) {
      height: calc(var(--vh, 1vh) * 100 - 67px);
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 15px;
    position: relative;
    background: #252525;
    color: #f2f2f2;
    width: 100%;
    padding: 15px 30px;
    @media (max-width: 480px) {
      padding: 15px 7px;
    }
  }
  &__close-button {
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: none;
    transition: 0.3s;
    // height: 30px;
    // width: 30px;
    cursor: pointer;
    border-radius: 5px;
    &:hover {
      transition: 0.3s;
      transform: rotate(180deg);
      // background: #ffacac;
    }
  }
  &__form {
    padding: 30px;
    @media (max-width: 620px) {
      padding: 15px;
    }
    @media (max-width: 480px) {
      padding: 7px;
    }
  }

  &__title {
    display: flex;
    font-size: 14px;
    flex-flow: column;
    align-items: flex-start;
    span {
      margin-bottom: 5px;
    }
  }
  &__clear-button {
    background: none;
    color: #8f8f8f;
    border-radius: 5px;
    padding: 0;
    border: none;
    text-align: left;
    cursor: pointer;
    font-size: 12px;
    transition: 0.3s;

    &:hover {
      transition: 0.3s;
      color: #f55;
    }
  }
  &__quantity {
    font-size: 14px;
    @media (max-width: 420px) {
      width: 120px;
    }
  }

  &__empty-section {
    width: 50%;
    min-height: 276px;
    margin: 30px auto;
    opacity: 0.2;
    height: calc(100% - 190px);
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__empty-img {
    // fill: #0391fd;
    margin-bottom: 30px;
    width: 50%;
  }
  &__empty-text {
    text-align: center;
    font-size: 20px;
    font-weight: 900;
    text-transform: uppercase;
    color: #0391fd;
  }
  &__footer {
    width: 100%;
    align-self: flex-end;
    padding: 15px 30px;
    border-top: 1px solid #eee;
    z-index: 100000;
    position: relative;
    box-shadow: 0 -40px 40px rgba(255, 255, 255, 0.9);
    color: #252525;
    @media (max-width: 480px) {
      padding: 15px 7px;
    }
  }
  &__footer-inner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__informer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__informer-text {
    width: calc(100% - 115px);
    font-size: 14px;
    line-height: 1.2;
  }
  &__informer-button {
    width: 100px;
    background: #0391fd;
    color: #fff;
    border-radius: 5px;
    padding: 0 15px;
    border: none;
    cursor: pointer;
    transition: 0.3s;
    font-size: 14px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__checkout {
    background: #0391fd;
    color: #fff;
    border-radius: 5px;
    padding: 0 15px;
    border: none;
    cursor: pointer;
    transition: 0.3s;
    font-size: 14px;
    width: 160px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      transition: 0.3s;
      background: #1bbc9b;
    }
  }
}
</style>
