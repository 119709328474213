<template>
  <div
    class="sb-catalog__inner"
    v-if="GET_PRODUCT_LIST_DATA.length && GET_CONFIG_LIST_DATA != undefined"
  >
    <!-- <h1 class="sb-catalog__title">Каталог</h1> -->
    <div class="sb-catalog__list">
      <template
        v-for="(product, index) in GET_PRODUCT_LIST_DATA"
        :key="product.product_id"
      >
        <catalog-list-item :product="product" :time="index" />
      </template>
    </div>
  </div>
  <div class="sb-catalog__footer">
    <catalog-paginator :category="false" />
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import { mapGetters } from "vuex";
import CatalogListItem from "@/components/CatalogListItem.vue";
import CatalogPaginator from "@/components/CatalogPaginator.vue";

export default {
  name: "CatalogView",
  components: {
    // HelloWorld,
    CatalogListItem,
    CatalogPaginator,
  },
  computed: {
    ...mapGetters([
      "GET_PRODUCT_LIST_DATA",
      "GET_PRODUCT_COUNT_DATA",
      "GET_CONFIG_LIST_DATA",
      "GET_BASKET_DATA",
    ]),
  },
  beforeCreate() {
    // window.scrollTo(0, 0);
    // window.scrollTo({
    //   top: 0,
    //   behavior: "smooth",
    // });
    this.$store.dispatch("PRODUCT_LIST_ACTION");
    this.$store.dispatch("PRODUCT_COUNT_ACTION");
    this.$store.dispatch("CONFIG_LIST_ACTION");
    // window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss">
// sb-catalog
.sb-catalog {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  &__inner {
    width: 100%;
    margin: 0 auto;
  }
  &__list {
    width: 102%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1% -2% -1%;
  }
  &__title {
    width: 100%;
  }

  &__footer {
    margin: 2% 0;
  }
}
</style>
