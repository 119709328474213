<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view />
  <Teleport to="body">
    <main-basket />
  </Teleport>
  <app-alert />
</template>

<script>
import axios from "axios";
import MainBasket from "@/components/MainBasket.vue";
import AppAlert from "@/components/AppAlert.vue";

export default {
  components: {
    MainBasket,
    AppAlert,
  },
  beforeCreate() {
    let ls_data = localStorage.getItem("sb_ls");
    if (ls_data != null && ls_data.length) {
      ls_data = JSON.parse(localStorage.getItem("sb_ls"));
    }

    let ls_data_id_arr = [];
    if (ls_data !== null) {
      for (let data of ls_data) {
        ls_data_id_arr.push({
          product_id: data.product_id,
          product_quantity: data.product_quantity,
        });
        // console.log("+++++---+++++");
        // console.log(ls_data_id_arr);
        // console.log("+++++---+++++");
      }
    }
    // Актуализация товаров в корзине
    if (ls_data_id_arr.length) {
      axios({
        url: "/admin/product/front/check_basket_product.php",
        method: "post",
        headers: { "Content-Type": "multipart/form-data" },
        data: {
          arr_id: ls_data_id_arr,
        },
      }).then((response) => {
        // console.log("---***---");
        // console.log(response);
        // console.log("---***---");

        this.$store.commit("SET_BASKET_DATA", response.data);
        localStorage.setItem("sb_ls", JSON.stringify(response.data));
      });
    }

    // if (localStorage.getItem("sb_ls") !== null) {
    //   this.$store.commit(
    //     "SET_BASKET_DATA",
    //     JSON.parse(localStorage.getItem("sb_ls"))
    //   );
    // }
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600&display=swap");
.sb-catalog {
  font-family: "Nunito", sans-serif !important;
  button {
    font-family: "Nunito", sans-serif !important;
  }
  // font-family: "Inter", sans-serif;
}
.sb-basket {
  font-family: "Nunito", sans-serif !important;
  button {
    font-family: "Nunito", sans-serif !important;
  }
  // font-family: "Inter", sans-serif;
}
.sb-basket-button {
  font-family: "Nunito", sans-serif !important;
  button {
    font-family: "Nunito", sans-serif !important;
  }
  // font-family: "Inter", sans-serif;
}

.hidden {
  overflow: hidden;
}

input,
textarea {
  font-family: "Inter", sans-serif;
  font-size: 14px;
}
</style>
