<template>
  <div class="sb-basket__textarea-wrapper">
    <textarea
      v-model="value"
      :name="name_val"
      :placeholder="placeholder_val"
      :maxlength="maxlength_val"
      :minlength="minlength_val"
      class="sb-basket__textarea"
      @blur="validTextarea()"
      @focus="error = ''"
    ></textarea>
    <span class="sb-basket__error-msgs">{{ error }}</span>
  </div>
</template>

<script>
export default {
  props: {
    input_value: Object,
  },
  data() {
    return {
      maxlength_val: this.input_value.maxlength_val,
      minlength_val: this.input_value.minlength_val,
      placeholder_val: this.input_value.required_val
        ? this.input_value.placeholder + " *"
        : this.input_value.placeholder,
      required_val: this.input_value.required_val,
      type_val: this.input_value.type_val,
      name_val: this.input_value.name_val,
      value: "",
      error: "",
    };
  },

  methods: {
    validTextarea() {
      if (this.required_val && this.value.length < Number(this.minlength_val)) {
        this.error = "Заполните корректно поле";
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
.sb-basket {
  &__textarea-wrapper {
    width: 100%;
    margin-bottom: 25px;
    position: relative;
  }
  &__textarea {
    padding: 15px;
    height: 150px;
    border: 1px solid #eee;
    border-radius: 3px;
    width: 100%;
    transition: 0.3s;
    outline: none;
    &:focus {
      transition: 0.3s;
      border-color: #0391fd;
    }
  }
}
</style>
