<template>
  <div
    class="sb-catalog__quantity-input"
    v-if="product.product_is_quantity > 0 ? true : false"
  >
    <button
      class="sb-catalog__quantity-button sb-catalog__quantity-button_minus"
      @click="change_quantity('-')"
    >
      <img
        width="16"
        height="16"
        src="@/assets/img/arrow.svg"
        alt="Уменьшить количество"
      />
    </button>
    <span class="sb-catalog__quantity-number">{{ basketDataItem }}</span>
    <button
      class="sb-catalog__quantity-button sb-catalog__quantity-button_plus"
      @click="change_quantity('+')"
    >
      <img
        width="16"
        height="16"
        src="@/assets/img/arrow.svg"
        alt="Уменьшить количество"
      />
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    product: Object,
  },
  data() {
    return {
      quantity: 1,
    };
  },
  computed: {
    ...mapGetters(["GET_BASKET_DATA"]),
    // count() {
    //   if (this.GET_BASKET_DATA[this.product.product_id]) {
    //     return this.GET_BASKET_DATA[this.product.product_id].product_quantity;
    //   }

    //   return 1;
    // },

    basketDataItem() {
      let basket_data = this.GET_BASKET_DATA;
      let new_basket_data = basket_data.find(
        (item) => item.product_id == this.product.product_id
      );
      if (new_basket_data) {
        return Number(new_basket_data.product_quantity);
      }
      return Number(1);
    },
  },
  methods: {
    change_quantity(operator) {
      console.log(this.basketDataItem);
      if (operator == "-" && this.basketDataItem == 1) {
        console.log("del");
        this.delete_product_from_basket(this.product.product_id);
        return false;
      }
      if (operator == "+") {
        this.quantity = Number(this.basketDataItem) + 1;

        console.log("tut");
        console.log(this.quantity);
        console.log("tut");
      }

      if (operator == "-" && this.basketDataItem > 1) {
        this.quantity = this.basketDataItem - 1;
      }

      // получаю текущие данные корзины
      let basket_data = this.GET_BASKET_DATA; // []
      let new_basket_data = basket_data.filter(
        (item) => item.product_id !== this.product.product_id
      );
      let basket_item = this.product;
      basket_item.product_basket_id =
        this.product.product_id + "_" + Math.random(1000);
      basket_item.product_quantity = this.quantity;

      this.product_price =
        Math.floor(
          this.product.product_price * basket_item.product_quantity * 100
        ) / 100;
      this.product_price_old =
        Math.floor(
          this.product.product_price_old * basket_item.product_quantity * 100
        ) / 100;
      new_basket_data.push(basket_item);
      this.$store.commit("SET_BASKET_DATA", new_basket_data);
      localStorage.setItem("sb_ls", JSON.stringify(new_basket_data));
    },

    delete_product_from_basket(id) {
      let basket_data = this.GET_BASKET_DATA;
      let new_basket_data = basket_data.filter(
        (item) => item.product_id !== id
      );
      console.log("del" + " " + id);
      console.log(new_basket_data);
      this.$store.commit("SET_BASKET_DATA", new_basket_data);
      localStorage.setItem("sb_ls", JSON.stringify(new_basket_data));
    },
  },
  beforeMount() {
    console.log("updated");
    if (this.GET_BASKET_DATA[this.product.product_id]) {
      this.quantity =
        this.GET_BASKET_DATA[this.product.product_id].product_quantity;
    }
  },
};
</script>

<style lang="scss">
.sb-catalog {
  &__quantity-input {
    border: 1px solid #f0f0f0;
    border-radius: 30px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 48%;
    @media (max-width: 768px) {
      width: 100%;
      margin-top: 10px;
    }
  }

  &__quantity-button {
    font-size: 20px;
    line-height: 0;
    border: none;
    background: #f9f9f9;
    color: #3d3d3d;
    height: 38px;
    width: 30px;
    cursor: pointer;

    @media (max-width: 480px) {
      height: 33px;
    }
    &_minus {
      transform: rotate(180deg);
    }
  }
}
</style>
